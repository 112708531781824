<template>
  <div class="main-content">
    <div style="position: relative" class="content">
      <el-container>
        <CommonTreeNew
          ref="commonTree"
          treeTitle="组织架构"
          :defaultProps="Props"
          :showCheckbox="false"
          @getNodeClick="handleNode"
          :urlParmas="{
            tenantId: '',
            deptCategory: '5,2',
            parentId: userInfo.dept_id,
          }"
          node-key="id"
          :isShowdig="false"
          :isZoom="true"
          :show="show"
          @showChange="showChange"
        ></CommonTreeNew>

        <el-main style="position: relative">
          <head-layout :head-btn-options="headBtnOptions" head-title="风险发布">
          </head-layout>
          <grid-head-layout
            ref="searchForm"
            :searchSpan="'4'"
            :search-columns="searchColumns"
            @grid-head-search="handleSearch"
            @grid-head-empty="searchReset"
          />
          <grid-layout
            ref="gridLayout"
            :data-total="tableTotal"
            :page="page"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :tableOptions="tableOptions"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            @gird-handle-select-click="selectionChange"
          >
            <template #customBtn="{ row, index }">
              <el-button
                type="text"
                size="small"
                @click="linkDetail(row, index)"
              >
                查看
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="handleFlow(row, index)"
              >
                流程图
              </el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
      <common-dialog
        v-if="bpmnVisible"
        width="70%"
        customClass="wf-dialog"
        :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)"
        :showBtn="false"
        @cancel="bpmnVisible = false"
      >
        <wf-design
          ref="bpmn"
          style="height: 60vh"
          :options="bpmnOption"
        ></wf-design>
      </common-dialog>
    </div>
  </div>
</template>

<script>
import { riskPublishPage } from "@/api/riskManage/project";
import {
  sinomatodo as getList,
  detail,
  batchCompleteTask,
} from "@/api/plugin/workflow/process";

import { mapGetters } from "vuex";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonDialog from "@/components/CommonDialog";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
    CommonDialog,
  },
  mixins: [exForm],
  computed: {
    ...mapGetters(["userInfo", "language"]),
    headBtnOptions() {
      let result = [];
      return result;
    },
  },
  data() {
    return {
      treeNode: {},
      Props: {
        children: "children",
        label: "title",
      },
      riskVersionId: "",

      show: true,

      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },

      searchColumns: [
        {
          placeholder: "请选择发布状态",
          type: "select",
          prop: "pushStatus",
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=process_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
      ],

      tableOptions: {
        menuFixed: "right",
        customRowAddBtn: false,
        menuWidth: 200,
        showTooltip: true,
        indexLabel: "序号",
        selection: false,
        column: [
          {
            label: "版本名称",
            prop: "pushName",
            align: "center",
            overHidden: true,
            minWidth: 120,
          },
          {
            label: "发布人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
            minWidth: 120,
          },
          {
            label: "发布时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
            minWidth: 180,
          },
          {
            label: "发布数量",
            prop: "publishNum",
            align: "center",
            overHidden: true,
            minWidth: 100,
          },
          {
            label: "发布状态",
            prop: "pushStatus",
            align: "center",
            dataType: "String",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=process_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
            minWidth: 100,
          },
        ],
      },

      tableData: [],

      tableTotal: 0,
      tableLoading: false,

      searchForm: {},
      bpmnVisible: false,
      bpmnOption: {},
    };
  },

  mounted() {},
  methods: {
    showChange(v) {
      this.show = v;
    },

    handleNode(node) {
      this.treeNode = node;
      this.page.currentPage = 1;
      this.onload(this.page);
    },
    handleSearch(searchForm) {
      this.searchForm = searchForm;
      this.page.currentPage = 1;
      this.onload(this.page);
    },
    onload(page) {
      this.tableLoading = true;
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage,
        organizationId: this.treeNode.id,
        ...this.searchForm,
      };
      riskPublishPage(params)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },

    linkDetail(row) {
      this.$router.push({
        path: "/riskPublish/riskPublishDetail",
        query: {
          mainId: row.id,
          organizationId: row.organizationId,
        },
      });
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onload(this.page);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onload(this.page);
    },

    selectionChange(arr) {
      this.ids = arr
        .map((item) => {
          return item.id;
        })
        .join(",");
    },

    searchReset() {
      this.searchForm = {};
      this.page.currentPage = 1;
      this.onload(this.page);
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleFlow(row) {
      detail({ processInsId: row.processId }).then((res) => {
        const { process, flow } = res.data.data;

        this.bpmnOption = {
          mode: "view",
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language,
        };

        this.bpmnVisible = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.leftQuarter {
  width: 200px;
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #ffffff;
  border-right: 1px solid #cccccc;
}

.riskClass {
  ::v-deep .common_tree_box .common_tree_handle {
    width: 240px;
  }
}

.quarterBox {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px solid #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 8px;
  position: relative;

  .deleteIcon {
    font-size: 16px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    color: #f56c6c;
  }
}

.quarterActiveClass {
  border: 1px solid #2979ff;
  background-color: #b3d8ff;
}

.quarterAddBox {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px dashed #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
}

.introduceClass ::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 421px) !important;
}

::v-deep .introduceTable .avue-crud .el-table {
  height: calc(100vh - 385px) !important;
  max-height: calc(100vh - 385px) !important;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .canvas-box {
      border-radius: 4px;
      width: 50%;
      height: 100%;
      background: #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      .title-box {
        width: calc(100% - 20px);
        position: absolute;
        z-index: 10;
        left: 10px;
        top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottom {
    border-radius: 4px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    width: 100%;
    height: calc(50% - 10px);
    overflow: scroll;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

.el-main {
  overflow: hidden;
}

.tagBox {
  position: absolute;
  left: 100px;
  top: 9px;
  z-index: 99;
}
</style>
