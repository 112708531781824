var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c(
      "div",
      { staticClass: "content", staticStyle: { position: "relative" } },
      [
        _c(
          "el-container",
          [
            _c("CommonTreeNew", {
              ref: "commonTree",
              attrs: {
                treeTitle: "组织架构",
                defaultProps: _vm.Props,
                showCheckbox: false,
                urlParmas: {
                  tenantId: "",
                  deptCategory: "5,2",
                  parentId: _vm.userInfo.dept_id,
                },
                "node-key": "id",
                isShowdig: false,
                isZoom: true,
                show: _vm.show,
              },
              on: { getNodeClick: _vm.handleNode, showChange: _vm.showChange },
            }),
            _c(
              "el-main",
              { staticStyle: { position: "relative" } },
              [
                _c("head-layout", {
                  attrs: {
                    "head-btn-options": _vm.headBtnOptions,
                    "head-title": "风险发布",
                  },
                }),
                _c("grid-head-layout", {
                  ref: "searchForm",
                  attrs: {
                    searchSpan: "4",
                    "search-columns": _vm.searchColumns,
                  },
                  on: {
                    "grid-head-search": _vm.handleSearch,
                    "grid-head-empty": _vm.searchReset,
                  },
                }),
                _c("grid-layout", {
                  ref: "gridLayout",
                  attrs: {
                    "data-total": _vm.tableTotal,
                    page: _vm.page,
                    tableData: _vm.tableData,
                    tableLoading: _vm.tableLoading,
                    tableOptions: _vm.tableOptions,
                  },
                  on: {
                    "page-size-change": _vm.handleSizeChange,
                    "page-current-change": _vm.handleCurrentChange,
                    "page-refresh-change": _vm.onLoad,
                    "gird-handle-select-click": _vm.selectionChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customBtn",
                      fn: function ({ row, index }) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.linkDetail(row, index)
                                },
                              },
                            },
                            [_vm._v("\n              查看\n            ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFlow(row, index)
                                },
                              },
                            },
                            [_vm._v("\n              流程图\n            ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.bpmnVisible
          ? _c(
              "common-dialog",
              {
                attrs: {
                  width: "70%",
                  customClass: "wf-dialog",
                  dialogTitle: _vm.$t(
                    `cip.desk.workflow.title.indexHeadVisible`
                  ),
                  showBtn: false,
                },
                on: {
                  cancel: function ($event) {
                    _vm.bpmnVisible = false
                  },
                },
              },
              [
                _c("wf-design", {
                  ref: "bpmn",
                  staticStyle: { height: "60vh" },
                  attrs: { options: _vm.bpmnOption },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }